
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  props: {},
  components: { Breadcrumbs },
})
export default class MergePreview extends Vue {
  zzlApi: any;
  condition: any = { idNumber: "" };
  columnFields: any = [];

  tableConfig: any = {
    member: {
      title: "人员信息",
      fields: [],
      list: [],
      config: {},
    },
    memberCompany: {
      title: "从业轨迹",
      fields: [],
      list: [],
      config: {},
    },
    memberPracticeCertRecord: {
      title: "执业证记录",
      fields: [],
      list: [],
      config: {},
    },
    summaryMemberCompany: {
      title: "从业轨迹中间表",
      fields: [],
      list: [],
      config: {
        status: {
          option: [
            { value: "F", label: "正常" },
            { value: "L", label: "失效" },
          ],
        },
      },
    },
    creditV2Log: {
      title: "诚信记录(新)",
      fields: [],
      list: [],
      config: {},
    },
    creditLog: {
      title: "诚信记录(旧)",
      fields: [],
      list: [],
      config: {},
    },
    creditChangeLog: {
      title: "诚信分数变更记录",
      fields: [],
      list: [],
      config: {},
    },
    creditClassLog: {
      title: "诚信级别记录",
      fields: [],
      list: [],
      config: {},
    },
    memberTraining: {
      title: "培训记录",
      fields: [],
      list: [],
      config: {
        status: {
          option: [
            { value: "A", label: "进行中" },
            { value: "F", label: "已达标" },
          ],
        },
      },
    },
    memberBusinessIndicators: {
      title: "业务指标-离职导入",
      fields: [],
      list: [],
      config: {
        status: { option: [] },
      },
    },
    memberCompanyAchievement: {
      title: "业务指标-在职导入",
      fields: [],
      list: [],
      config: {
        status: { option: [] },
      },
    },
    memberRecognizance: {
      title: "承诺书",
      fields: [],
      list: [],
      config: {},
    },
    memberQualification: {
      title: "资质信息",
      fields: [],
      list: [],
      config: {
        status: {
          option: [
            { value: "A", label: "有效状态" },
            { value: "F", label: "无效状态" },
          ],
        },
      },
    },
    memberQualificationRecord: {
      title: "资质变更记录",
      fields: [],
      list: [],
      config: {
        changeType: {
          option: [
            { value: "1", label: "常规升级" },
            { value: "2", label: "一般失信降级" },
            { value: "3", label: "严重失信降级" },
          ],
        },
      },
    },
    memberRelative: {
      title: "亲属信息",
      fields: [],
      list: [],
      config: {},
    },
    termination: {
      title: "被解约信息",
      fields: [],
      list: [],
      config: {},
    },
  };
  memberModel: any; //人员信息
  memberCompanyModelList: any = []; // 从业轨迹
  memberCompanyModelIdList: any = []; // 从业轨迹删除Id
  memberPracticeCertRecordModelList: any = []; // 执业证记录
  memberPracticeCertRecordModelIdList: any = []; // 执业证记录删除Id
  summaryMemberCompanyModelList: any = []; // 从业轨迹中间表
  summaryMemberCompanyModelIdList: any = []; // 从业轨迹中间表删除I
  creditLogModelList: any = []; // 诚信记录
  creditLogModelIdList: any = []; // 诚信记录删除Id
  creditV2LogModelList: any = []; // 新版诚信记录
  creditV2LogModelIdList: any = []; // 新版诚信记录删除Id
  creditV2ClassLogModelIdList:any = []; //新版诚信级别记录删除Id
  creditV2ChangeLogModelIdList:any = []; //新版诚信分数变更记录删除Id

  creditClassLogModelList: any = []; // 诚信级别记录
  creditClassLogModelIdList: any = []; // 诚信级别记录删除Id
  creditChangeLogModelList: any = []; // 诚信分数变更记录
  creditChangeLogModelIdList: any = []; // 诚信分数变更记录删除Id
  memberTrainingModelList: any = []; // 培训记录
  memberTrainingModelIdList: any = []; // 培训记录记录删除Id
  memberBusinessIndicatorsModelList: any = []; // 业务指标-离职导入
  memberBusinessIndicatorsModelIdList: any = []; // 业务指标-离职导入删除Id
  memberCompanyAchievementModelList: any = []; // 业务指标-在职导入
  memberCompanyAchievementModelIdList: any = []; // 业务指标-在职导入删除Id
  memberRecognizanceModelList: any = []; // 承诺书-在职导入
  memberRecognizanceModelIdList: any = []; // 承诺书删除Id
  memberQualificationModelList: any = []; // 资质信息
  memberQualificationModelIdList: any = []; // 资质信息删除Id
  memberQualificationRecordModelList: any = []; // 资质变更记录
  memberQualificationRecordModelIdList: any = []; // 资质变更记录删除Id
  memberRelativeModelList: any = []; // 亲属信息
  memberRelativeModelIdList: any = []; // 亲属信息删除Id
  terminationModelList: any = []; // 被解约信息
  terminationModelIdList: any = []; //被解约信息删除Id

  resetCondition() {}
  async clearAll() {
    if (!this.condition.idNumber) {
      this.$message.warning("证件号码不能为空");
      return;
    }
    this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        const loading = this.$loading({
          lock: true,
          text: "删除中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        const res = await this.zzlApi.member.DeleteMember({
          idnumber: this.condition.idNumber,
        });
        loading.close();
        if (res.code === 0) {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        } else {
          this.$message({
            type: "success",
            message: res.msg,
          });
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "删除失败",
        });
      });
  }
  async getData() {
    let detail: any = JSON.parse(sessionStorage.getItem("idMerge")!);
    const res = await this.zzlApi.member.GetCertificateMerge(detail, true);
    this.tableConfig.member.list = [];
    if (res.code === 0 && res.data) {
      for (let key in res.data) {
        this.tableConfig[key].list = res.data[key] || [];
        if (res.data[key].length > 0)
          this.tableConfig[key].fields = Object.keys(res.data[key][0]);
      }
    }
  }
  getCell(ev: any) {
    let parentNode: any = ev.target.parentNode;
    while (parentNode && !parentNode.classList.contains("cell")) {
      parentNode = parentNode.parentNode;
    }
    return parentNode;
  }
  editAction(ev: any, row: any, field: string) {
    this.$set(row, `${field}Edit`, true);
    this.$set(row, "isEdit", true);
  }
  async saveAction(row: any, keyName: string) {
    // if(!row.isEdit) return;
    let res = await this.zzlApi[keyName.toLowerCase()].Update({ ...row });
    if (res.code == 0) {
      //   this.$set(row,"isEdit",false);
      // for(let field in row){
      //     if(row[`${field}Edit`])
      //     row[`${field}Edit`]=false;
      // }
      this.$message.success("更新成功");
    } else {
      this.$message.error("更新失败");
    }
  }
  async deleteAction(row: any, keyName: any, type: any) {
  
    let ids: any = [];
    if (!row)
      ids = (this.$refs[`${keyName}Ref`] as any)[0].selection.map((m: any) => {
        return m.id;
      });
    else ids.push(row.id);
    if (!ids.length) {
      this.$message.warning("请至少选择一条数据");
      return;
    }

    // this.tableConfig[keyName].list.filter((item) => item.id !==row.id)
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        if (type == "all") {
          ids.map((item, index) => {
            if (this.tableConfig[keyName].list) {
              this.tableConfig[keyName].list.map((item2, index2) => {
                if (item === item2.id) {
                  this.tableConfig[keyName].list.splice(index2, 1);
                  switch (keyName) {
                    case "memberCompany":
                      this.memberCompanyModelIdList.push(item2.id);
                      break;
                    case "memberPracticeCertRecord":
                      this.memberPracticeCertRecordModelIdList.push(item2.id);
                      break;
                    case "summaryMemberCompany":
                      this.summaryMemberCompanyModelIdList.push(item2.id);
                      break;
                    case "creditV2Log":
                      this.creditV2LogModelIdList.push(item2.id);
                      this.creditV2ChangeLogModelIdList.push(...item2.creditV2ChangeLogModelIdList || []);
                      this.creditV2ClassLogModelIdList.push(...item2.creditV2ClassLogModelIdList || []);
                      break;
                    case "creditLog":
                      this.creditLogModelIdList.push(item2.id);
                      break;
                    case "creditClassLog":
                      this.creditClassLogModelIdList.push(item2.id);
                      break;
                    case "creditChangeLog":
                      this.creditChangeLogModelIdList.push(item2.id);
                      break;
                    case "memberTraining":
                      this.memberTrainingModelIdList.push(item2.id);
                      break;
                    case "memberBusinessIndicators":
                      this.memberBusinessIndicatorsModelIdList.push(item2.id);
                      break;
                    case "memberCompanyAchievement":
                      this.memberCompanyAchievementModelIdList.push(item2.id);
                      break;
                    case "memberRecognizance":
                      this.memberRecognizanceModelIdList.push(item2.id);
                      break;
                    case "memberQualification":
                      this.memberQualificationModelIdList.push(item2.id);
                      break;
                    case "memberQualificationRecord":
                      this.memberQualificationRecordModelIdList.push(item2.id);
                      break;
                    case "memberRelative":
                      this.memberRelativeModelIdList.push(item2.id);
                      break;
                    case "termination":
                      this.terminationModelIdList.push(item2.id);
                      break;
                    default:
                  }
                }
              });
            }
          });
        } else {
          if (this.tableConfig[keyName].list) {
            this.tableConfig[keyName].list.map((item, index) => {
              if (item.id === row.id) {
                this.tableConfig[keyName].list.splice(index, 1);
              }
            });
          }
          switch (keyName) {
            case "memberCompany":
              this.memberCompanyModelIdList.push(...ids);
              break;
            case "memberPracticeCertRecord":
              this.memberPracticeCertRecordModelIdList.push(...ids);
              break;
            case "summaryMemberCompany":
              this.summaryMemberCompanyModelIdList.push(...ids);
              break;
            case "creditV2Log":
              this.creditV2LogModelIdList.push(...ids);
              this.creditV2ChangeLogModelIdList.push(...row.creditV2ChangeLogModelIdList || []);
              this.creditV2ClassLogModelIdList.push(...row.creditV2ClassLogModelIdList || []);
              break;
            case "creditLog":
              this.creditLogModelIdList.push(...ids);
              break;
            case "creditClassLog":
              this.creditClassLogModelIdList.push(...ids);
              break;
            case "creditChangeLog":
              this.creditChangeLogModelIdList.push(...ids);
              break;
            case "memberTraining":
              this.memberTrainingModelIdList.push(...ids);
              break;
            case "memberBusinessIndicators":
              this.memberBusinessIndicatorsModelIdList.push(...ids);
              break;
            case "memberCompanyAchievement":
              this.memberCompanyAchievementModelIdList.push(...ids);
              break;
            case "memberRecognizance":
              this.memberRecognizanceModelIdList.push(...ids);
              break;
            case "memberQualification":
              this.memberQualificationModelIdList.push(...ids);
              break;
            case "memberQualificationRecord":
              this.memberQualificationRecordModelIdList.push(...ids);
              break;
            case "memberRelative":
              this.memberRelativeModelIdList.push(...ids);
              break;
            case "termination":
              this.terminationModelIdList.push(...ids);
              break;
            default:
          }
        }
        this.$message.success("删除成功");
      })
      .catch(() => {
        this.$message.info("已取消");
      });
  }
  @Watch("$store.state.stateStatus")
  async initData() {
    if (this.$store.state.stateStatus) {
      if (this.$store.state.sex) {
        this.tableConfig.memberBusinessIndicators.config.status.option =
          this.$store.state.businessOperatorStatus;
        this.columnFields = (
          (await import("./PersonalDetailsConfig")) as any
        ).default;
      }
    }
  }
  changeValue(val, type) {
    switch (type) {
      case "member":
        this.memberModel = val;
        this.memberModel = this.filterEdit(this.memberModel);
        break;
      case "memberCompany":
        this.memberCompanyModelList.push(val);
        this.memberCompanyModelList = this.filterEdit(
          this.memberCompanyModelList
        );
        break;
      case "memberPracticeCertRecord":
        this.memberPracticeCertRecordModelList.push(val);
        this.memberPracticeCertRecordModelList = this.filterEdit(
          this.memberPracticeCertRecordModelList
        );
        break;
      case "summaryMemberCompany":
        this.summaryMemberCompanyModelList.push(val);
        this.summaryMemberCompanyModelList = this.filterEdit(
          this.summaryMemberCompanyModelList
        );
        break;
      case "creditV2Log":
        this.creditV2LogModelList.push(val);
        this.creditV2LogModelList = this.filterEdit(this.creditV2LogModelList);
        break;
      case "creditLog":
        this.creditLogModelList.push(val);
        this.creditLogModelList = this.filterEdit(this.creditLogModelList);
        break;
      case "creditClassLog":
        this.creditClassLogModelList.push(val);
        this.creditClassLogModelList = this.filterEdit(
          this.creditClassLogModelList
        );
        break;
      case "creditChangeLog":
        this.creditChangeLogModelList.push(val);
        this.creditChangeLogModelList = this.filterEdit(
          this.creditChangeLogModelList
        );
        break;
      case "memberTraining":
        this.memberTrainingModelList.push(val);
        this.memberTrainingModelList = this.filterEdit(
          this.memberTrainingModelList
        );
        break;
      case "memberBusinessIndicators":
        this.memberBusinessIndicatorsModelList.push(val);
        this.memberBusinessIndicatorsModelList = this.filterEdit(
          this.memberBusinessIndicatorsModelList
        );
        break;
      case "memberCompanyAchievement":
        this.memberCompanyAchievementModelList.push(val);
        this.memberCompanyAchievementModelList = this.filterEdit(
          this.memberCompanyAchievementModelList
        );
        break;
      case "memberRecognizance":
        this.memberRecognizanceModelList.push(val);
        this.memberRecognizanceModelList = this.filterEdit(
          this.memberRecognizanceModelList
        );
        break;
      case "memberQualification":
        this.memberQualificationModelList.push(val);
        this.memberQualificationModelList = this.filterEdit(
          this.memberQualificationModelList
        );
        break;
      case "memberQualificationRecord":
        this.memberQualificationRecordModelList.push(val);
        this.memberQualificationRecordModelList = this.filterEdit(
          this.memberQualificationRecordModelList
        );
        break;
      case "memberRelative":
        this.memberRelativeModelList.push(val);
        this.memberRelativeModelList = this.filterEdit(
          this.memberRelativeModelList
        );
        break;
      case "termination":
        this.terminationModelList.push(val);
        this.terminationModelList = this.filterEdit(this.terminationModelList);
        break;
      default:
    }
  }
  //去重每组重复的
  filterEdit(arr: any) {
    let obj: any = {};
    arr = arr.reduce((newArr, next) => {
      obj[next.id] ? "" : (obj[next.id] = true && newArr.push(next));
      return newArr;
    }, []);
    return arr;
  }
  //确定保存数据
  async confirmMerge() {
    this.$confirm("此操作将永久保存该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(async () => {
      let detail: any = JSON.parse(sessionStorage.getItem("idMerge")!);
      let params = {
        getCertificateMerge:{
          ...detail
        },
        memberModel: this.memberModel || "",
        memberCompanyModelList: this.memberCompanyModelList || [],
        memberCompanyModelIdList: this.memberCompanyModelIdList || [],
        memberPracticeCertRecordModelList:
          this.memberPracticeCertRecordModelList || [],
        memberPracticeCertRecordModelIdList:
          this.memberPracticeCertRecordModelIdList || [],
        summaryMemberCompanyModelList: this.summaryMemberCompanyModelList || [],
        summaryMemberCompanyModelIdList:
          this.summaryMemberCompanyModelIdList || [],
        creditV2LogModelList: this.creditV2LogModelList || [],
        creditV2LogModelIdList: this.creditV2LogModelIdList || [],
        creditLogModelList: this.creditLogModelList || [],
        creditLogModelIdList: this.creditLogModelIdList || [],
        creditClassLogModelList: this.creditClassLogModelList || [],
        creditClassLogModelIdList: this.creditClassLogModelIdList || [],
        creditChangeLogModelList: this.creditChangeLogModelList || [],
        creditChangeLogModelIdList: this.creditChangeLogModelIdList || [],
        memberTrainingModelList: this.memberTrainingModelList || [],
        memberTrainingModelIdList: this.memberTrainingModelIdList || [],
        memberBusinessIndicatorsModelList:
          this.memberBusinessIndicatorsModelList || [],
        memberBusinessIndicatorsModelIdList:
          this.memberBusinessIndicatorsModelIdList || [],
        memberCompanyAchievementModelList:
          this.memberCompanyAchievementModelList || [],
        memberCompanyAchievementModelIdList:
          this.memberCompanyAchievementModelIdList || [],
        memberRecognizanceModelList: this.memberRecognizanceModelList || [],
        memberRecognizanceModelIdList: this.memberRecognizanceModelIdList || [],
        memberQualificationModelList: this.memberQualificationModelList || [],
        memberQualificationModelIdList:
          this.memberQualificationModelIdList || [],
        memberQualificationRecordModelList:
          this.memberQualificationRecordModelList || [],
        memberQualificationRecordModelIdList:
          this.memberQualificationRecordModelIdList || [],
        memberRelativeModelList: this.memberRelativeModelList || [],
        memberRelativeModelIdList: this.memberRelativeModelIdList || [],
        terminationModelList: this.terminationModelList || [],
        terminationModelIdList: this.terminationModelIdList || [],
        creditV2ClassLogModelIdList: this.creditV2ClassLogModelIdList || [],
        creditV2ChangeLogModelIdList: this.creditV2ChangeLogModelIdList || []
      };
      const res = await this.zzlApi.member.CertificateMergeSave(params, true);
      if (res.code === 0) {
        this.$message.success("保存成功");
        this.memberCompanyModelIdList = []; // 从业轨迹删除Id
        this.memberPracticeCertRecordModelIdList = []; // 执业证记录删除Id
        this.summaryMemberCompanyModelIdList = []; // 从业轨迹中间表删除I
        this.creditLogModelIdList = []; // 诚信记录删除Id
        this.creditV2LogModelIdList = []; // 新版诚信记录删除Id
        this.creditV2ClassLogModelIdList= []; //新版诚信级别记录删除Id
        this.creditV2ChangeLogModelIdList= []; //新版诚信分数变更记录删除Id
        this.creditClassLogModelIdList = []; // 诚信级别记录删除Id
        this.creditChangeLogModelIdList = []; // 诚信分数变更记录删除Id
        this.memberTrainingModelIdList = []; // 培训记录记录删除Id
        this.memberBusinessIndicatorsModelIdList = []; // 业务指标-离职导入删除Id
        this.memberCompanyAchievementModelIdList = []; // 业务指标-在职导入删除Id
        this.memberRecognizanceModelIdList = []; // 承诺书删除Id
        this.memberQualificationModelIdList = []; // 资质信息删除Id
        this.memberQualificationRecordModelIdList = []; // 资质变更记录删除Id
        this.memberRelativeModelIdList = []; // 亲属信息删除Id
        this.terminationModelIdList = []; //被解约信息删除Id
      } else {
        this.$message.info("保存失败");
      }
    });
  }
  //返回合并数据页
  back() {
    this.$router.push({
      name: "IdNumberMerge",
    });
  }
  mounted() {
    this.initData();
    this.getData();
  }
}
